'use client'

import { useState, useEffect } from 'react'

import styles from './StaticMenuItems.module.scss'
import classNames from 'classnames/bind'

import ChevronDown from '@/icons/chevron-down.svg'

const classes = classNames.bind(styles)

const StaticMenuItems = ({ type }) => {
    const [pricingLink, setPricingLink] = useState('/#pricing-section')

    useEffect(() => {
        setPricingLink(document.getElementById('pricing-section') ? '#pricing-section' : '/#pricing-section')
    }, [])

    return (
        <>
            <div className={classes('menu-item', `${type == 'default' ? 'white-bg' : ''}`)}>
                <a href={pricingLink}>Pricing</a>
            </div>
            <div
                id={classes('services-menu')}
                className={classes('menu-item', `${type == 'default' ? 'white-bg' : ''}`)}
            >
                <a href={'#'}>
                    Services
                    <ChevronDown className={classes('chevron-down')} />
                </a>
                <div className={classes('arrow')}>
                    <div />
                </div>
                <div className={classes('child-menu-wrap')}>
                    <div className={classes('child-menu')}>
                        <div className={classes('child-group')}>
                            <a className={classes('group-title')}>Services</a>
                            <a className={classes('child-link')} href={'/personal'}>
                                Personal Assessment
                            </a>
                            <a className={classes('child-link')} href={'/company'}>
                                Company Tax Return
                            </a>
                            <a className={classes('child-link')} href={'/partnership-tax-return-sa800'}>
                                Partnership Tax Return
                            </a>
                        </div>
                        <div className={classes('child-group')}>
                            <a className={classes('group-title')}>Who We Support</a>
                            <a className={classes('child-link')} href={'/buy-to-let'}>
                                Landlords
                            </a>
                            <a className={classes('child-link')} href={'/landlord-non-res'}>
                                Non Resident Landlords
                            </a>
                            <a className={classes('child-link')} href={'/non-res-foreign'}>
                                Expats
                            </a>
                            <a className={classes('child-link')} href={'/self-employed'}>
                                Sole Traders
                            </a>
                            <a className={classes('child-link')} href={'/high-earner'}>
                                Employees
                            </a>
                            <a className={classes('child-link')} href={'/company'}>
                                LTD Directors
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes('menu-item', `${type == 'default' ? 'white-bg' : null}`)}>
                <a href={'#'}>
                    Resources
                    <ChevronDown className={classes('chevron-down')} />
                </a>
                <div className={classes('arrow')}>
                    <div />
                </div>
                <div className={classes('child-menu-wrap')}>
                    <div className={classes('child-menu')}>
                        <div className={classes('child-group', 'calculators-box')}>
                            <a className={classes('group-title')} href={'/calculators'}>
                                Calculators
                            </a>
                            <a className={classes('child-link')} href={'/uk-tax-residence-calculator'}>
                                Tax residence Calculator
                            </a>
                            <a className={classes('child-link')} href={'/remittance-basis-calculator'}>
                                Remittance basis Calculator
                            </a>
                            <a className={classes('child-link')} href={'/home-pay-calculator'}>
                                Take home pay Calculator
                            </a>
                            <a className={classes('child-link')} href={'/pension-calculator'}>
                                Pension charge Calculator
                            </a>
                            <a className={classes('view-all')} href={'/calculators'}>
                                View all calculators
                            </a>
                        </div>
                        <div className={classes('child-big-group')}>
                            <div className={classes('child-group')}>
                                <a className={classes('group-title')} href={'/blog'}>
                                    Blogs
                                </a>
                                <a className={classes('view-all')} href={'/blog'}>
                                    View all articles
                                </a>
                            </div>
                            <div className={classes('child-group')}>
                                <span className={classes('group-title')}>Useful links</span>
                                <a className={classes('child-link')} href={'/about-us'}>
                                    About us
                                </a>
                                <a className={classes('child-link')} href={'/faqs'}>
                                    FAQs
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes('menu-item', `${type == 'default' ? 'white-bg' : null}`)}>
                <a href={'https://dashboard.taxd.co.uk/'}>Login</a>
            </div>
        </>
    )
}

export default StaticMenuItems
